export const sitePages = [
  {
    text: "Page d'accueil",
    value: "/",
  },
  {
    text: "Page A propos",
    value: "/apropos",
  },
  {
    text: "Page Bureau d'études",
    value: "/bureau-detudes",
  },
  {
    text: "Page Inline",
    value: "/inline",
  },
  {
    text: "Page Service Interventions",
    value: "/interventions",
  },
  {
    text: "Page Service Charpente",
    value: "/services/charpente",
  },
  {
    text: "Page Service Couverture",
    value: "/services/couverture",
  },
  {
    text: "Page Service Zinguerie",
    value: "/services/zinguerie",
  },
  {
    text: "Page Façades",
    value: "/services/facades",
  },
  {
    text: "Page Interventions",
    value: "/services/interventions",
  },
  {
    text: "Page Contact",
    value: "/contact",
  },
];
